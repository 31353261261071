<template>
<!-- 城市开箱不良 -->
  <div class="layout_common city-unpack">
    <div class="main_box">
      <div class="main_box_con">
        <searchHead
          compType="city-unpack"
          :storeInfo="storeInfo"
          @search="search"
          @reset="search"
          @rankClick="rankClick"
          @saleArea="saleArea"
        />
        <div class="echarts" v-loading="loading">
          <lineEcharts :propData="echartsData" :type="type" :searchParams="searchParams" />
        </div>
      </div>
    </div>
    <!-- 商家发货排名 -->
    <rank
      :isShow="isShow"
      @close="close"
      @selectStore="selectStore"
    />
  </div>
</template>
<script>
import searchHead from '@/views/data-center/components/searchHead'
import lineEcharts from './lineEcharts'
import rank from '../components/rank'
import { formatDate, diffDate } from '@/utils/common'
import { unpackBadEcharts } from '@/api'
export default {
  components: {
    searchHead,
    lineEcharts,
    rank
  },
  data () {
    return {
      loading: false,
      isShow: false,
      storeInfo: {},
      storeId: '',
      storeName: '',
      startTime: '',
      endTime: '',
      timeType: 3,
      type: 1,
      level: '',
      cityId: '',
      echartsData: [],
      searchParams: {} // 收集搜索的参数  传到折线图点击跳转需携带参数
    }
  },
  mounted () {

  },
  methods: {
    // 子组件传过来战区信息
    saleArea (salesArea, level, cityId) {
      this.level = level
      this.cityId = cityId
      this.searchParams = {
        storeId: this.storeId,
        storeName: this.storeName,
        timeType: this.timeType,
        level,
        cityId,
        salesArea
      }
      // 缺省默认6个月
      this.endTime = formatDate(new Date()).slice(0, 7)
      this.startTime = diffDate(-5, 'months', true).slice(0, 7)
      this.getList()
    },
    getList () {
      const params = {
        level: this.level,
        cityId: this.cityId,
        type: this.type,
        timeType: this.timeType,
        storeId: this.storeId,
        startTime: this.startTime,
        endTime: this.endTime
      }
      this.loading = true
      unpackBadEcharts(params).then(res => {
        this.loading = false
        this.echartsData = res.data
      })
    },
    // 点击发货量排名
    rankClick () {
      this.isShow = true
    },
    // 选择的商家
    selectStore (val) {
      this.storeId = val.storeId
      this.storeName = val.storeName
      this.storeInfo = {
        storeId: this.storeId,
        storeName: this.storeName
      }
    },
    close () {
      this.isShow = false
    },
    search (val) {
      const { level, cityId, salesArea, type, timeType, storeId, storeName, startTime, endTime } = val
      this.level = level
      this.cityId = cityId
      this.type = type
      this.timeType = timeType
      this.storeId = storeId
      this.startTime = startTime
      this.endTime = endTime
      this.searchParams = {
        storeId,
        storeName,
        timeType,
        level,
        cityId,
        salesArea
      }
      this.getList()
    }
  }
}
</script>
<style lang="scss" scoped>
.city-unpack {
  .echarts {
    height: calc(100% - 120px);
  }
}
</style>
