<template>
  <div id="city-unpack_box" style="width:100%;height:100%">
    <div id="city-unpack" style="width:100%;height:100%"></div>
    <notData v-if="propData.length == 0" />
  </div>
</template>
<script>
export default {
  props: {
    propData: {
      type: Array,
      default: () => []
    },
    type: {
      type: Number,
      default: 1
    },
    searchParams: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      data: []
    }
  },
  watch: {
    propData: {
      handler (n) {
        this.data = n
        this.init()
      },
      deep: true
    }
  },
  mounted () {
    this.init()
    window.addEventListener('resize', this.resizeCharts, true)
    this.$erd('city-unpack_box', 'city-unpack')
  },
  destroyed () {
    window.removeEventListener('resize', this.resizeCharts, true)
  },
  methods: {
    resizeCharts () {
      const myChart = this.$echarts.init(document.getElementById('city-unpack'))
      myChart.resize()
    },
    /* handleEnd (length, source1 = 10) {
      if (length <= source1) {
        return 100
      } else if (length > source1 && length < 40) {
        return 25
      } else {
        return 5
      }
    }, */
    init () {
      const myChart = this.$echarts.init(document.getElementById('city-unpack'))
      const dataX = this.data.map(item => item.deliveryTime)
      const dataY = this.data.map(item => item.rate)
      const option = {
        /* dataZoom: {
          show: dataX.length > 30,
          // realtime: true, // 拖动滚动条时是否动态的更新图表数据
          height: 10, // 滚动条高度
          start: 0, // 滚动条开始位置（共100等份）
          end: this.handleEnd(dataX.length),
          bottom: '0'
        }, */
        grid: {
          left: '2%',
          right: '2%',
          bottom: '1%',
          containLabel: true
        },
        tooltip: {
          show: true,
          trigger: 'axis',
          backgroundColor: 'rgba(0,0,0,.7)',
          padding: 0,
          confine: true,
          formatter: (params) => {
            const relVal = `<div style="
                    border-bottom:1px solid #A8A8A8;
                    line-height:36px;
                    color:#fff;
                    padding:0 10px;">
                    ${params[0].name}
                    <span style="color:#FF7F00">点击查看详情</span>
                </div>
                <div style="line-height:36px;padding:0 10px;color:#fff;">
                    <div>
                      开箱不良率:&nbsp;&nbsp;
                      <span style="float:right">${
                        params[0].value + '%'
                      }</span>
                    </div>
                    <div>
                      ${this.type === 1 ? '发货台数' : this.type === 2 ? '发货客户' : '发货订单'}:&nbsp;&nbsp;
                      <span style="float:right">${
                        this.data[params[0].dataIndex].deliveryCount
                      }</span>
                    </div>
                    <div>
                      开箱不良:&nbsp;&nbsp;
                      <span style="float:right">${
                        this.data[params[0].dataIndex].packCount
                      }</span>
                    </div>
                </div>
                `
            return relVal
          }
        },
        xAxis: [{
          type: 'category',
          axisLabel: {
            color: '#1B1C29',
            fontSize: 12,
            margin: 10
          },
          axisTick: {
            // 坐标轴刻度相关设置。
            show: false
          },
          axisLine: {
            // 坐标轴轴线相关设置
            lineStyle: {
              type: 'dashed',
              color: '#e6ecf0'
            }
          },
          splitLine: {
            // 坐标轴在 grid 区域中的分隔线。
            show: false
          },
          data: dataX
        }],
        yAxis: [{
          type: 'value',
          // name: '开箱不良率',
          // nameTextStyle: {
          //   padding: [0, 50, 0, 0]
          // },
          axisLabel: {
            formatter: function (value) {
              return value + '%'
            }
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: '#ddd'
            }
          }
        }],
        series: [{
          data: dataY,
          type: 'line',
          label: {
            normal: {
              show: true,
              color: '#000',
              position: 'top'
            }
          },
          markLine: {
            silent: true
          },
          itemStyle: {
            normal: {
              color: '#fac858'
            }
          },
          lineStyle: {
            width: 1,
            color: '#fac858'
          }
        }]
      }
      myChart.setOption(option)
      // 点击事件
      myChart.getZr().on('click', (params) => {
        const pointInPixel = [params.offsetX, params.offsetY]
        if (myChart.containPixel('grid', pointInPixel)) {
          var pointInGrid = myChart.convertFromPixel({ seriesIndex: 0 }, pointInPixel)
          // X轴序号
          var xIndex = pointInGrid[0]
          // 获取当前图表的option
          var op = myChart.getOption()
          // 获得图表中我们想要的数据
          var time = op.xAxis[0].data[xIndex]
          // 跳转分析页面
          const {
            storeId,
            storeName,
            timeType,
            level,
            cityId,
            salesArea
          } = this.searchParams
          const route = this.$router.resolve({
            name: 'city-unpack-analy',
            query: {
              storeId,
              storeName,
              timeType,
              level,
              cityId,
              salesArea: salesArea.join(','),
              time
            }
          })
          window.open(route.href, '_blank')
        }
      })
      // 将可以响应点击事件的范围内，鼠标样式设为pointer--------------------
      myChart.getZr().on('mousemove', function (params) {
        var pointInPixel = [params.offsetX, params.offsetY]
        if (myChart.containPixel('grid', pointInPixel)) {
          myChart.getZr().setCursorStyle('pointer')
        };
      })
      myChart.on('mouseout', function (params) {
        var pointInPixel = [params.offsetX, params.offsetY]
        if (!myChart.containPixel('grid', pointInPixel)) {
          myChart.getZr().setCursorStyle('default')
        };
      })
    }
  }
}
</script>
